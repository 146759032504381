var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, AlertError, AlertWarning, } from '@dimatech/shared/lib/components/Alert';
import { Button, Input, Label } from '@dimatech/shared/lib/components/form';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { EventType } from '@dimatech/shared/lib/models';
import { trackEvent } from '@dimatech/shared/lib/tracking';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { useResetPasswordMutation } from '../../../api/authentication/authenticationApi';
export var Welcome = withTheme(function (_a) {
    var theme = _a.theme;
    var t = useTranslation().t;
    var _b = useParams().resetToken, resetToken = _b === void 0 ? '' : _b;
    var _c = useResetPasswordMutation(), resetPassword = _c[0], _d = _c[1], error = _d.error, isLoading = _d.isLoading, isSuccess = _d.isSuccess;
    var _e = useState(''), password = _e[0], setPassword = _e[1];
    var _f = useState(''), passwordVerification = _f[0], setPasswordVerification = _f[1];
    var _g = useState(false), isPasswordNotMatching = _g[0], setIsPasswordNotMatching = _g[1];
    var handleSubmit = function (e) {
        e.preventDefault();
        setIsPasswordNotMatching(false);
        if (!password) {
            return;
        }
        if (password !== passwordVerification) {
            setIsPasswordNotMatching(true);
            return;
        }
        resetPassword({ password: password, passwordVerification: passwordVerification, resetToken: resetToken });
        trackEvent(EventType.AuthPasswordReset, {
            welcome: true,
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Heading2, { children: t('Welcome.Title') }), isPasswordNotMatching && _jsx(Error, { children: t('Welcome.PasswordMismatch') }), !isPasswordNotMatching && error && (_jsxs(Error, { children: [error.name ? t("Errors.".concat(error.name)) : t('Errors.Generic'), error.name === 'NotFound' && (_jsx("div", __assign({ style: { marginTop: 10 } }, { children: _jsx(Link, __assign({ to: "/forgot-password", style: { color: theme.colors.onError } }, { children: t('Password.Reset.ForgotPasswordLink') })) })))] })), !isSuccess && (_jsxs(_Fragment, { children: [_jsx(Text, { children: t('Welcome.Text') }), _jsxs("div", { children: [_jsx(Label, __assign({ htmlFor: "newPassword" }, { children: t('Welcome.NewPassword') })), _jsx(Input, { type: "password", id: "newPassword", name: "newPassword", onChange: function (event) { return setPassword(event.currentTarget.value); } })] }), _jsxs("div", { children: [_jsx(Label, __assign({ htmlFor: "newPasswordVerification" }, { children: t('Welcome.NewPasswordVerification') })), _jsx(Input, { type: "password", id: "newPasswordVerification", name: "newPasswordVerification", onChange: function (event) {
                                    return setPasswordVerification(event.currentTarget.value);
                                } })] }), _jsx(Button, __assign({ onClick: handleSubmit, style: { width: '100%', marginTop: 10 }, disabled: isLoading || !password }, { children: isLoading ? t('Welcome.Submitting') : t('Welcome.Submit') }))] })), isSuccess && _jsx(AlertText, { children: t('Welcome.Verification') }), !isSuccess && (_jsx(Info, { children: _jsx(Trans, { i18nKey: "Password.Rules" }) })), _jsx(BackLink, { children: _jsx(Link, __assign({ to: "/login" }, { children: t('Welcome.Back') })) })] }));
});
Welcome.displayName = 'Welcome';
var BackLink = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 15px;\n\n  font-size: ", ";\n"], ["\n  margin-top: 15px;\n\n  font-size: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.s;
});
var Text = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 15px 0 10px;\n"], ["\n  margin: 15px 0 10px;\n"])));
var AlertText = styled(Alert)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 15px 0 10px;\n"], ["\n  margin: 15px 0 10px;\n"])));
var Info = styled(AlertWarning)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 15px 0 0;\n  padding: 10px;\n"], ["\n  margin: 15px 0 0;\n  padding: 10px;\n"])));
var Error = styled(AlertError)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 15px 0 10px;\n"], ["\n  margin: 15px 0 10px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
