import { useContext, useEffect, useState } from 'react';
import { ApplicationContext } from '../application';
export var useTitle = function (title) {
    var application = useContext(ApplicationContext);
    var _a = useState(''), previousTitle = _a[0], setPreviousTitle = _a[1];
    var setTitle = function (title) {
        if (title) {
            document.title = "".concat(application.title, " - ").concat(title);
        }
        else if (title !== null) {
            document.title = application.title;
        }
    };
    useEffect(function () {
        setPreviousTitle(document.title);
        setTitle(title);
        return function () {
            document.title = previousTitle;
        };
    }, []);
    return { setTitle: setTitle };
};
