import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { LogoutBadge } from '@dimatech/features-core/lib/features/authentication/components';
import { Badge } from '@dimatech/shared/lib/components/Badge';
import { DevToolbar } from '@dimatech/shared/lib/components/dev-tools';
import {
  Header as SharedHeader,
  SwitchLocale,
  SwitchTheme,
} from '@dimatech/shared/lib/components/workspace';
import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import { initialsFromEmail } from '@dimatech/shared/lib/utils';
import { dark } from 'assets/themes/dark';
import { light } from 'assets/themes/light';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const Header = ({
  isSidePanelShown,
  setIsSidePanelShown,
}: {
  isSidePanelShown?: boolean;
  setIsSidePanelShown?: (isShown: boolean) => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const { accessToken, webToken } = useContext(AuthenticationContext);
  const initials = initialsFromEmail(accessToken.user?.email);

  return (
    <SharedHeader
      isSidePanelShown={isSidePanelShown}
      setIsSidePanelShown={setIsSidePanelShown}
    >
      <DevToolbar
        token={accessToken.token}
        userId={accessToken.user?.id}
        customerId={accessToken.customerId}
        tokenExpires={accessToken.expires}
        webToken={webToken.token}
        webTokenExpires={webToken.expires}
      />

      <SwitchTheme dark={dark.name} light={light.name} />
      <SwitchLocale />

      {accessToken.user && (
        <>
          <LogoutBadge style={{ marginLeft: 10 }} />

          <Badge style={{ marginLeft: 20 }}>{initials}</Badge>

          <User>
            <div>{accessToken.user.email}</div>
            <div>{accessToken.user.currentCustomer.name}</div>
            <div>
              {accessToken.user.currentCustomer.roles
                .map((role) => t(`Roles.${role}`))
                .join(', ')}
            </div>
          </User>
        </>
      )}
    </SharedHeader>
  );
};

Header.displayName = 'Header';

const User = styled.div`
  margin-left: 10px;

  > div:first-of-type {
    color: ${({ theme }: { theme: Theme }) => theme.colors.primary};
  }

  @media screen and (max-width: ${Breakpoints.small}) {
    display: none;
  }
`;
