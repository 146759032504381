var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BsCaretLeftFill, BsCaretRightFill } from 'react-icons/bs';
import styled from 'styled-components';
import { EventType } from '../../models';
import { trackEvent } from '../../tracking';
import { Dots, usePagination } from './usePagination';
export var Pagination = function (_a) {
    var totalCount = _a.totalCount, pageSize = _a.pageSize, _b = _a.siblingCount, siblingCount = _b === void 0 ? 2 : _b, currentPage = _a.currentPage, handlePageChange = _a.handlePageChange, style = _a.style;
    var paginationRange = usePagination({
        currentPage: currentPage,
        totalCount: totalCount,
        siblingCount: siblingCount,
        pageSize: pageSize,
    });
    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }
    var handleNext = function () {
        if (currentPage === lastPage) {
            return;
        }
        trackEvent(EventType.PaginatorPageChange, {
            from: currentPage,
            to: currentPage + 1,
        });
        handlePageChange(currentPage + 1);
    };
    var handlePrevious = function () {
        if (currentPage === 1) {
            return;
        }
        trackEvent(EventType.PaginatorPageChange, {
            from: currentPage,
            to: currentPage - 1,
        });
        handlePageChange(currentPage - 1);
    };
    var lastPage = paginationRange[paginationRange.length - 1];
    return (_jsxs(Style, __assign({ style: style }, { children: [_jsx(Arrow, __assign({ "$disabled": currentPage === 1, onClick: handlePrevious }, { children: _jsx(BsCaretLeftFill, {}) })), paginationRange.map(function (pageNumber, index) {
                if (pageNumber === Dots) {
                    return _jsx(Delimiter, { children: "..." }, index);
                }
                return (_jsx(Item, __assign({ "$selected": pageNumber === currentPage, onClick: function () {
                        trackEvent(EventType.PaginatorPageChange, {
                            from: currentPage,
                            to: pageNumber,
                        });
                        handlePageChange(pageNumber);
                    } }, { children: pageNumber }), index));
            }), _jsx(Arrow, __assign({ "$disabled": currentPage === lastPage, onClick: handleNext }, { children: _jsx(BsCaretRightFill, {}) }))] })));
};
Pagination.displayName = 'Pagination';
var Style = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  list-style-type: none;\n  width: 100%;\n  justify-content: center;\n\n  margin: 15px 0 0px 5px;\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  list-style-type: none;\n  width: 100%;\n  justify-content: center;\n\n  margin: 15px 0 0px 5px;\n"])));
var Delimiter = styled.li(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 5px 0;\n  padding: 3px 0;\n  min-height: 30px;\n  min-width: 30px;\n  text-align: center;\n"], ["\n  margin: 5px 0;\n  padding: 3px 0;\n  min-height: 30px;\n  min-width: 30px;\n  text-align: center;\n"])));
var Item = styled.li(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 5px;\n  min-height: 30px;\n  min-width: 30px;\n  cursor: pointer;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  border-radius: 50%;\n\n  font-size: ", ";\n\n  color: ", ";\n\n  background-color: ", ";\n\n  &:hover {\n    box-shadow: ", ";\n\n    color: ", ";\n\n    background-color: ", ";\n  }\n"], ["\n  margin: 5px;\n  min-height: 30px;\n  min-width: 30px;\n  cursor: pointer;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  border-radius: 50%;\n\n  font-size: ", ";\n\n  color: ", ";\n\n  background-color: ", ";\n\n  &:hover {\n    box-shadow: ", ";\n\n    color: ", ";\n\n    background-color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.s;
}, function (props) {
    return props.$selected ? props.theme.colors.onSecondary : 'unset';
}, function (props) {
    return props.$selected ? props.theme.colors.secondary : 'unset';
}, function (_a) {
    var theme = _a.theme;
    return theme.boxShadows.default;
}, function (props) {
    return !props.$selected
        ? props.theme.colors.onPrimary
        : props.theme.colors.onSecondary;
}, function (props) {
    return !props.$selected
        ? props.theme.colors.primary
        : props.theme.colors.secondary;
});
var Arrow = styled.li(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 0px;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  text-align: center;\n  cursor: ", ";\n\n  color: ", ";\n"], ["\n  margin: 0px;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  text-align: center;\n  cursor: ", ";\n\n  color: ", ";\n"])), function (props) { return (props.$disabled ? 'unset' : 'pointer'); }, function (props) { return (props.$disabled ? props.theme.colors.border : 'unset'); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
