var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import i18next from 'i18next';
import { useMemo, useState } from 'react';
import { EventType, SortDirection } from '../models';
import { trackEvent } from '../tracking';
export var useSortableData = function (items, config) {
    if (config === void 0) { config = null; }
    var _a = useState(config), sortConfiguration = _a[0], setSortConfiguration = _a[1];
    var sortedItems = useMemo(function () {
        var sortableItems = __spreadArray([], (items !== null && items !== void 0 ? items : []), true);
        if (!sortConfiguration) {
            return sortableItems;
        }
        sortableItems.sort(function (a, b) {
            var _a;
            var _b, _c;
            /* eslint-disable @typescript-eslint/no-explicit-any */
            var valueA = '', valueB = '';
            /* eslint-enable @typescript-eslint/no-explicit-any */
            var key = sortConfiguration.key;
            if (sortConfiguration.predicate) {
                (_a = sortConfiguration.predicate(a, b), valueA = _a.a, valueB = _a.b);
            }
            else {
                valueA = (_b = a[key]) !== null && _b !== void 0 ? _b : '';
                valueB = (_c = b[key]) !== null && _c !== void 0 ? _c : '';
            }
            if (typeof valueA === 'string' && typeof valueB === 'string') {
                // Make sure string values are sorted case insensitive
                return valueA === null || valueA === void 0 ? void 0 : valueA.localeCompare(valueB, i18next.language, {
                    sensitivity: 'base',
                });
            }
            if (valueA < valueB) {
                return -1;
            }
            if (valueA > valueB) {
                return 1;
            }
            return 0;
        });
        return sortConfiguration.direction === SortDirection.Asc
            ? sortableItems
            : sortableItems.reverse();
    }, [items, sortConfiguration]);
    var requestSort = function (key, predicate) {
        var direction = SortDirection.Asc;
        if (sortConfiguration &&
            sortConfiguration.key === key &&
            sortConfiguration.direction === SortDirection.Asc) {
            direction = SortDirection.Desc;
        }
        trackEvent(EventType.Sort, {
            key: key,
            direction: direction,
        });
        setSortConfiguration({ key: key, predicate: predicate, direction: direction });
    };
    return { items: sortedItems, sorter: { requestSort: requestSort, sortConfiguration: sortConfiguration } };
};
