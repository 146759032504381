import { useEffect, useState } from 'react';
import { EventType } from '../models';
import { trackEvent } from '../tracking';
export var getTheme = function () {
    return window.localStorage.getItem('theme-name');
};
export var useThemeMode = function (defaultThemeName, supportedThemes) {
    var _a = useState(defaultThemeName), themeName = _a[0], setThemeName = _a[1];
    var saveThemeName = function (name) {
        window.localStorage.setItem('theme-name', name);
        setThemeName(name);
    };
    var switchTheme = function (name) {
        supportedThemes.includes(name) && saveThemeName(name);
        trackEvent(EventType.ThemeChange, {
            name: name,
        });
    };
    useEffect(function () {
        var _a;
        var name = (_a = getTheme()) !== null && _a !== void 0 ? _a : defaultThemeName;
        !!name && setThemeName(name);
    }, []);
    return [themeName, switchTheme];
};
