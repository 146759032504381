var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, AlertError, AlertWarning, } from '@dimatech/shared/lib/components/Alert';
import { Button, Input, Label } from '@dimatech/shared/lib/components/form';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { EventType } from '@dimatech/shared/lib/models';
import { trackEvent } from '@dimatech/shared/lib/tracking';
import { isValidPassword } from '@dimatech/shared/lib/utils';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { useChangePasswordMutation } from '../../../api/authentication/authenticationApi';
export var ChangePassword = withTheme(function (_a) {
    var theme = _a.theme;
    var t = useTranslation().t;
    var _b = useChangePasswordMutation(), changePassword = _b[0], _c = _b[1], error = _c.error, isLoading = _c.isLoading, isSuccess = _c.isSuccess;
    var _d = useState(''), password = _d[0], setPassword = _d[1];
    var _e = useState(''), newPassword = _e[0], setNewPassword = _e[1];
    var _f = useState(''), newPasswordVerification = _f[0], setNewPasswordVerification = _f[1];
    var _g = useState(false), isNewPasswordNotMatching = _g[0], setIsNewPasswordNotMatching = _g[1];
    var handleSubmit = function (e) {
        e.preventDefault();
        setIsNewPasswordNotMatching(false);
        if (!password || !newPassword) {
            return;
        }
        if (!isValidPassword(newPassword)) {
            return;
        }
        if (newPassword !== newPasswordVerification) {
            setIsNewPasswordNotMatching(true);
            return;
        }
        changePassword({ password: password, newPassword: newPassword }).unwrap().catch();
        trackEvent(EventType.AuthPasswordChange);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Heading2, { children: t('Password.Change.Title') }), isNewPasswordNotMatching && (_jsx(Error, { children: t('Password.Change.PasswordMismatch') })), !isNewPasswordNotMatching && error && (_jsxs(Error, { children: [error.name === 'PasswordMismatch'
                        ? t('Password.Change.PasswordIncorrect')
                        : error.name
                            ? t("Errors.".concat(error.name))
                            : t('Errors.Generic'), (error.name === 'NotFound' ||
                        error.name === 'PasswordMismatch') && (_jsx("div", __assign({ style: { marginTop: 10 } }, { children: _jsx(Link, __assign({ to: "/forgot-password", style: { color: theme.colors.onError } }, { children: t('Password.Change.ForgotPasswordLink') })) })))] })), !isSuccess && (_jsxs(_Fragment, { children: [_jsx(Text, { children: t('Password.Change.Text') }), _jsxs("div", { children: [_jsx(Label, __assign({ htmlFor: "password" }, { children: t('Password.Change.Password') })), _jsx(Input, { type: "password", id: "password", name: "password", onChange: function (event) { return setPassword(event.currentTarget.value); } })] }), _jsxs("div", __assign({ style: { marginTop: 30 } }, { children: [_jsx(Label, __assign({ htmlFor: "newPassword" }, { children: t('Password.Change.NewPassword') })), _jsx(Input, { type: "password", id: "newPassword", name: "newPassword", onChange: function (event) { return setNewPassword(event.currentTarget.value); } })] })), _jsxs("div", { children: [_jsx(Label, __assign({ htmlFor: "newPasswordVerification" }, { children: t('Password.Change.NewPasswordVerification') })), _jsx(Input, { type: "password", id: "newPasswordVerification", name: "newPasswordVerification", onChange: function (event) {
                                    return setNewPasswordVerification(event.currentTarget.value);
                                } })] }), _jsx(Button, __assign({ onClick: handleSubmit, style: { width: '100%', marginTop: 10 }, disabled: isLoading || !password }, { children: isLoading
                            ? t('Password.Change.Submitting')
                            : t('Password.Change.Submit') }))] })), isSuccess && (_jsx(AlertText, { children: t('Password.Change.Verification') })), !isSuccess && (_jsx(Info, { children: _jsx(Trans, { i18nKey: "Password.Rules" }) }))] }));
});
ChangePassword.displayName = 'ChangePassword';
var Text = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 15px 0 10px;\n"], ["\n  margin: 15px 0 10px;\n"])));
var AlertText = styled(Alert)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 15px 0 10px;\n"], ["\n  margin: 15px 0 10px;\n"])));
var Info = styled(AlertWarning)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 15px 0 0;\n  padding: 10px;\n"], ["\n  margin: 15px 0 0;\n  padding: 10px;\n"])));
var Error = styled(AlertError)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 15px 0 10px;\n"], ["\n  margin: 15px 0 10px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
