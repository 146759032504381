import { CacheTagsLicense, featuresCoreApi } from '../featuresCoreApi';
var licenseApi = featuresCoreApi.injectEndpoints({
    endpoints: function (build) { return ({
        /**
         * Get license type
         */
        getLicenseType: build.query({
            query: function (_a) {
                var productId = _a.productId, organizationId = _a.organizationId, isConsortiumLicense = _a.isConsortiumLicense, isBaseVersion = _a.isBaseVersion, countryCode = _a.countryCode;
                return "licenses/licenseType?1=1".concat(productId ? "&productId=".concat(productId) : '').concat(organizationId ? "&organizationId=".concat(organizationId) : '', "&isConsortiumLicense=").concat(isConsortiumLicense, "&isBaseVersion=").concat(isBaseVersion).concat("&countryCode=".concat(countryCode));
            },
            providesTags: [CacheTagsLicense.Type],
        }),
    }); },
});
export var useLazyGetLicenseTypeQuery = licenseApi.useLazyGetLicenseTypeQuery, useGetLicenseTypeQuery = licenseApi.useGetLicenseTypeQuery;
