export var EventType;
(function (EventType) {
    EventType["AuthEmailConfirm"] = "auth_email_confirm";
    EventType["AuthLogin"] = "auth_login";
    EventType["AuthLogout"] = "auth_logout";
    EventType["AuthPasswordChange"] = "auth_password_change";
    EventType["AuthPasswordForgot"] = "auth_password_forgot";
    EventType["AuthPasswordReset"] = "auth_password_reset";
    EventType["AuthRegister"] = "auth_register";
    EventType["AuthRequestWebLoginToken"] = "auth_request_web_login_token";
    EventType["ChartClick"] = "chart_click";
    EventType["ChartLegendClick"] = "chart_legend_click";
    EventType["EmailBookDemo"] = "email_book_demo";
    EventType["FeatureFlagSave"] = "feature_flag_save";
    EventType["FilterChange"] = "filter_change";
    EventType["FilterReset"] = "filter_reset";
    EventType["GanttCategorySelect"] = "gantt_category_select";
    EventType["GanttItemSelect"] = "gantt_item_select";
    EventType["HeatmapClick"] = "heatmap_click";
    EventType["InternalMessagesClose"] = "internal_messages_close";
    EventType["InternalMessagesMarkAllAsRead"] = "internal_messages_mark_all_as_read";
    EventType["InternalMessagesOpen"] = "internal_messages_open";
    EventType["InternalMessagesRead"] = "internal_message_read";
    EventType["InternalMessagesShowAll"] = "internal_messages_show_all";
    EventType["InternalMessagesShowUnreadOnly"] = "internal_messages_show_unread_only";
    EventType["LocaleChange"] = "locale_change";
    EventType["NewsletterSubscribe"] = "newsletter_subscribe";
    EventType["PaginatorPageChange"] = "paginator_page_change";
    EventType["ProjectContact"] = "project_contact";
    EventType["ProjectFollow"] = "project_follow";
    EventType["ProjectUnfollow"] = "project_unfollow";
    EventType["ProjectDocumentExport"] = "project_document_export";
    EventType["QuestionMaturityStaircaseView"] = "question_maturity_staircase_view";
    EventType["ReportExport"] = "report_export";
    EventType["Search"] = "search";
    EventType["Sort"] = "sort";
    EventType["SurveyComplete"] = "survey_complete";
    EventType["SurveyLinksExport"] = "survey_links_export";
    EventType["SurveyNextPage"] = "survey_next_page";
    EventType["SurveyPreviousPage"] = "survey_previous_page";
    EventType["SurveySave"] = "survey_save";
    EventType["ThemeChange"] = "theme_change";
    EventType["UsersExport"] = "users_export";
})(EventType || (EventType = {}));
