var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, AlertError } from '@dimatech/shared/lib/components/Alert';
import { Button, Input, Label } from '@dimatech/shared/lib/components/form';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { EventType } from '@dimatech/shared/lib/models';
import { trackEvent } from '@dimatech/shared/lib/tracking';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useForgotPasswordMutation } from '../../../api/authentication/authenticationApi';
export var ForgotPassword = function () {
    var t = useTranslation().t;
    var _a = useState(''), email = _a[0], setEmail = _a[1];
    var _b = useForgotPasswordMutation(), forgotPassword = _b[0], _c = _b[1], error = _c.error, isLoading = _c.isLoading, isSuccess = _c.isSuccess;
    var handleSubmit = function (e) {
        e.preventDefault();
        forgotPassword({ email: email });
        trackEvent(EventType.AuthPasswordForgot);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Heading2, { children: t('Password.Forgot.Title') }), error && _jsx(Error, { children: t('Password.Forgot.NoUserHavingEmail') }), !isSuccess && (_jsxs(_Fragment, { children: [_jsx(Text, { children: t('Password.Forgot.Text') }), _jsxs("div", { children: [_jsx(Label, __assign({ htmlFor: "email" }, { children: t('Password.Forgot.Email') })), _jsx(Input, { type: "email", id: "email", name: "email", defaultValue: email, onChange: function (event) { return setEmail(event.currentTarget.value); } })] }), _jsx(Button, __assign({ onClick: handleSubmit, style: { width: '100%', marginTop: 10 }, disabled: isLoading }, { children: isLoading
                            ? t('Password.Forgot.Submitting')
                            : t('Password.Forgot.Submit') }))] })), isSuccess && _jsx(AlertText, { children: t('Password.Forgot.Verification') }), _jsx(BackLink, { children: _jsx(Link, __assign({ to: "/login" }, { children: t('Password.Forgot.Back') })) })] }));
};
ForgotPassword.displayName = 'ForgotPassword';
var BackLink = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 15px;\n\n  font-size: ", ";\n"], ["\n  margin-top: 15px;\n\n  font-size: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.s;
});
var Text = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 15px 0 10px;\n"], ["\n  margin: 15px 0 10px;\n"])));
var AlertText = styled(Alert)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 15px 0 10px;\n"], ["\n  margin: 15px 0 10px;\n"])));
var Error = styled(AlertError)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 15px 0 10px;\n"], ["\n  margin: 15px 0 10px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
