var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { BadgeSmall } from '@dimatech/shared/lib/components/Badge';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { EventType } from '@dimatech/shared/lib/models';
import { trackEvent } from '@dimatech/shared/lib/tracking';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsBoxArrowRight } from 'react-icons/bs';
import { AuthenticationContext } from '../AuthenticationProvider';
export var LogoutBadge = function (_a) {
    var style = _a.style;
    var t = useTranslation().t;
    var logout = useContext(AuthenticationContext).logout;
    var handleLogout = function () {
        logout();
        trackEvent(EventType.AuthLogout);
    };
    return (_jsxs(_Fragment, { children: [_jsx(TooltipContent, { id: "tt-user-logout", text: t('Logout.TooltipText') }), _jsx(BadgeSmall, __assign({ "data-tip": true, "data-for": "tt-user-logout", onClick: handleLogout, style: style }, { children: _jsx(BsBoxArrowRight, {}) })), ' '] }));
};
LogoutBadge.displayName = 'LogoutBadge';
